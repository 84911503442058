/* eslint-disable @typescript-eslint/no-explicit-any */

import { TRPCClientError } from "@trpc/client";
import axios from "axios";
import { FieldValues, Path, UseFormSetError } from "react-hook-form";
import { z } from "zod";

/**
 * Apply validation errors from the API to a form.
 */
export function applyApiValidationErrors<T extends FieldValues>(
	err: unknown,
	setError: UseFormSetError<T>,
) {
	let issues: z.ZodIssue[] = [];
	if (
		axios.isAxiosError(err) &&
		err.response?.data?.error?.type === "validation" &&
		Array.isArray(err.response?.data.error.issues)
	) {
		issues = err.response?.data.error.issues as z.ZodIssue[];
	} else if (
		err instanceof TRPCClientError &&
		err.data?.type === "validation" &&
		Array.isArray(err.data?.issues)
	) {
		issues = err.data?.issues as z.ZodIssue[];
	} else if ((err as any)?.type && (err as any)?.issues && (err as any)?.path) {
		issues = (err as any)?.issues;
	} else {
		return;
	}

	if (issues) {
		issues.forEach((issue) => {
			const fullPath = issue.path.join(".");
			setError(fullPath as Path<T>, {
				type: "manual",
				message: issue.message,
			});
		});
	}
}
