import { Attachment } from "@withjuly/julycons";
import { Loader, useToast, Text } from "@withjuly/solis";
import React from "react";
import { useState } from "react";
import { trpc } from "~/components/Utility/trpc";
import { useRichText } from "~/utils/context/rich-text";
import { BANNED_FILE_EXTENSIONS } from "./constants";

export interface EditorMenuFileInputProps {
	type: "file";
}

export const EditorMenuFileInput: React.FC<EditorMenuFileInputProps> = () => {
	const getAttachmentUploadUrl = trpc.template.attachment.upload.useMutation();
	const [isUploading, setIsUploading] = useState(false);
	const { setFiles } = useRichText();

	const { toast } = useToast();

	const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
		setIsUploading(true);
		const file = e.target.files?.[0];

		if (file) {
			// Validate file
			if (file.size > 10_000_000) {
				toast({
					title: "That file is too large",
					description: "Please upload a file less than 10MB",
					status: "danger",
				});
				setIsUploading(false);
				e.target.value = "";
				return;
			}

			if (BANNED_FILE_EXTENSIONS.includes(file.name.split(".")[1] ?? "")) {
				toast({
					title: "That file type is not allowed",
					description: "Gmail does not allow files of that type",
					status: "danger",
				});
				setIsUploading(false);
				e.target.value = "";
				return;
			}

			const { url, fields, upload } = await getAttachmentUploadUrl.mutateAsync({
				fileName: file.name,
				fileType: file.type,
				size: file.size,
			});

			const formData = new FormData();
			Object.entries(fields).forEach(([k, v]) => formData.append(k, v));
			formData.append("Content-Type", file.type);
			formData.append("file", file);
			await fetch(url, {
				method: "POST",
				body: formData,
			});

			setFiles((prev) => [
				...prev,
				{ uuid: upload.uuid, name: upload.fileName },
			]);
		}

		setIsUploading(false);
	};

	return (
		<div className="flex">
			<label
				className="flex cursor-pointer items-center gap-1 whitespace-nowrap rounded px-2 py-1 hover:bg-gray-400"
				htmlFor="attachment-upload"
				tabIndex={0}
			>
				<Text>Add a file</Text> <Attachment className="h-3 w-3" />
			</label>
			{isUploading ? (
				<Loader />
			) : (
				<input
					id="attachment-upload"
					type="file"
					className="hidden"
					onChange={onUpload}
				/>
			)}
		</div>
	);
};
