import React, { Dispatch, SetStateAction, useMemo } from "react";
import { ReactNode } from "react";

export interface AttachedFile {
	uuid: string;
	name: string;
}

interface RichTextContextData {
	files: AttachedFile[];
	setFiles: Dispatch<SetStateAction<AttachedFile[]>>;
}

export const RichTextContext = React.createContext<RichTextContextData>({
	files: [],
	setFiles: () => {
		// Do nothing
	},
});

export const RichTextProvider: React.FC<
	{ children: ReactNode } & RichTextContextData
> = ({ children, files, setFiles }) => {
	const value = useMemo(() => ({ files, setFiles }), [files, setFiles]);

	return (
		<RichTextContext.Provider value={value}>
			{children}
		</RichTextContext.Provider>
	);
};

export const useRichText = () => React.useContext(RichTextContext);
