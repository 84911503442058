import React, { useId } from "react";
import {
	FormInputWrapper,
	Checkbox as SolisCheckbox,
	useFormWrapper,
} from "@withjuly/solis";

export interface CheckboxProps {
	name: string;
	label?: string | React.ReactNode;
	disabled?: boolean;
	onChange?: (checked: boolean) => void;
}

export const Checkbox: React.FC<CheckboxProps> = ({ name, ...rest }) => {
	const id = useId();
	const { register, setValue, value, hasError } = useFormWrapper(name);
	const form = register(name);

	return (
		<FormInputWrapper id={id} name={name}>
			<SolisCheckbox
				checked={value}
				{...rest}
				{...form}
				onChange={(checked) => {
					setValue(checked);
					if (rest.onChange) {
						rest.onChange(checked);
					}
				}}
				isInvalid={hasError}
			/>
		</FormInputWrapper>
	);
};
