import { Editor } from "@tiptap/react";
import React from "react";
import { Fragment } from "react";
import { FiBold, FiItalic, FiLink, FiUnderline } from "react-icons/fi";
import { RiListOrdered, RiListUnordered } from "react-icons/ri";
import { EditorMenuItem, EditorMenuItemProps } from "./Menu/EditorMenuItem";
import { templateVariables } from "@withjuly/fabric";
import { cx } from "@withjuly/frontend-common";

const getDefaultMenuItems = (editor: Editor): EditorMenuItemProps[][] => {
	return [
		[
			{
				icon: FiBold,
				title: "Bold",
				action: () => editor.chain().focus().toggleBold().run(),
				isActive: () => editor.isActive("bold"),
				type: "button",
			},
			{
				icon: FiItalic,
				title: "Italic",
				action: () => editor.chain().focus().toggleItalic().run(),
				isActive: () => editor.isActive("italic"),
				type: "button",
			},
			{
				icon: FiUnderline,
				title: "Underline",
				action: () => editor.chain().focus().toggleUnderline().run(),
				isActive: () => editor.isActive("underline"),
				type: "button",
			},
		],
		[
			{
				icon: FiLink,
				title: "Link",
				action: (location) =>
					editor
						.chain()
						.focus()
						.extendMarkRange("link")
						.setLink({ href: location })
						.run(),
				type: "link",
			},
			{
				icon: RiListUnordered,
				title: "Bullet List",
				action: () => editor.chain().focus().toggleBulletList().run(),
				isActive: () => editor.isActive("bulletList"),
				type: "button",
			},
			{
				icon: RiListOrdered,
				title: "Ordered List",
				action: () => editor.chain().focus().toggleOrderedList().run(),
				isActive: () => editor.isActive("orderedList"),
				type: "button",
			},
		],
		[
			{
				type: "dropdown",
				title: "Variables",
				action: (option: { name: string; displayName: string }) => {
					editor.commands.insertContent(
						`<span data-type="july-template-variable" data-name="${option.name}"/>`,
					);
					editor?.commands.insertContent(" ");
				},
				options: Object.entries(templateVariables).map(([name, variable]) => ({
					name,
					displayName: variable.displayName,
				})),
			},
		],
		[
			{
				type: "file",
			},
		],
	];
};

export interface EditorMenuProps {
	editor: Editor | null;
	items?: EditorMenuItemProps[][];
	className?: string;
}

export const EditorMenu: React.FC<EditorMenuProps> = ({
	editor,
	items,
	className,
}) => {
	if (!items && editor) {
		items = getDefaultMenuItems(editor);
	} else {
		items = [];
	}

	return (
		<div
			className={cx(
				"flex h-12 items-center gap-4 overflow-x-auto p-2 pl-3",
				className ?? "",
			)}
		>
			{items.map((group, i) => (
				<div className="flex gap-1" key={i}>
					{group.map((item, i) => (
						<Fragment key={i}>
							<EditorMenuItem {...item} />
						</Fragment>
					))}
				</div>
			))}
		</div>
	);
};
