import { BaseSyntheticEvent, useCallback, useMemo } from "react";
import { UseFormReturn } from "react-hook-form";
import { z } from "zod";
import { useApiErrorHandler } from "../api/errors";
import { applyApiValidationErrors } from "../form";
import {
	useZodForm as useCommonZodForm,
	UseZodFormProps,
} from "@withjuly/frontend-common";

/**
 * Additional information passed to the form's submit action
 */
interface SubmitContext {
	onFormError: (error: unknown) => void;
}

/**
 * Handler for data after validation passes.
 * Takes in form error handler to use if the API call fails
 * data: Values captured from form
 * context: Function to call if the handling/api failes
 */
interface SubmitHandler<TFieldValues> {
	submit?: (data: TFieldValues, context: SubmitContext) => void;
}

export const useZodForm = <
	TSchema extends z.Schema,
	TFieldValues extends z.infer<TSchema> = z.infer<TSchema>,
>({
	schema,
	submit,
	...rest
}: UseZodFormProps<TSchema, TFieldValues> &
	SubmitHandler<TFieldValues>): UseFormReturn<TFieldValues> & {
	onSubmit: (e?: BaseSyntheticEvent | undefined) => Promise<void>;
} => {
	const { handleApiError } = useApiErrorHandler();
	const form = useCommonZodForm<TSchema, TFieldValues>({
		schema,
		...rest,
	});

	const { handleSubmit, setError } = form;

	// Utility method to call when submitting a form throws an error
	const onFormError = useCallback(
		(error: unknown) => {
			handleApiError(error);
			applyApiValidationErrors(error, setError);
		},
		[setError, handleApiError],
	);

	// Should be called to submit the form
	const onSubmit = useMemo(
		() => handleSubmit((data) => submit?.(data, { onFormError })),
		[handleSubmit, onFormError, submit],
	);

	return { ...form, onSubmit };
};
