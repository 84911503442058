import { Delete } from "@withjuly/julycons";
import { Text } from "@withjuly/solis";
import React from "react";

export interface AttachedFileProps {
	name: string;
	uuid?: string;
	onDownload: () => void;
	onRemove: () => void;
}

export const AttachedFile: React.FC<AttachedFileProps> = ({
	name,
	onDownload,
	onRemove,
}) => {
	return (
		<div
			className="border-gray-250 flex min-w-[12rem] max-w-[16rem] cursor-pointer items-center gap-6 rounded-lg border bg-gray-600 p-2 transition-all hover:border-gray-200"
			onClick={onDownload}
		>
			<Text className="truncate hover:underline" title={name}>
				{name}
			</Text>
			<Delete
				className="mr-auto h-auto min-h-0 w-6 min-w-0 cursor-pointer rounded bg-gray-400 p-1.5 text-base transition-all hover:bg-gray-300"
				aria-label="Remove"
				onClick={(e) => {
					e.stopPropagation();
					e.preventDefault();
					onRemove();
				}}
			/>
		</div>
	);
};
