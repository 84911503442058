// From https://support.google.com/mail/answer/6590?hl=en#zippy=%2Cmessages-that-have-attachments
export const BANNED_FILE_EXTENSIONS = [
	"ade",
	"adp",
	"apk",
	"appx",
	"appxbundle",
	"bat",
	"cab",
	"chm",
	"cmd",
	"com",
	"cpl",
	"diagcab",
	"diagcfg",
	"diagpack",
	"dll",
	"dmg",
	"ex",
	"ex_",
	"exe",
	"hta",
	"img",
	"ins",
	"iso",
	"isp",
	"jar",
	"jnlp",
	"js",
	"jse",
	"lib",
	"lnk",
	"mde",
	"msc",
	"msi",
	"msix",
	"msixbundle",
	"msp",
	"mst",
	"nsh",
	"pif",
	"ps1",
	"scr",
	"sct",
	"shb",
	"sys",
	"vb",
	"vbe",
	"vbs",
	"vhd",
	"vxd",
	"wsc",
	"wsf",
	"wsh",
	"xll",
];
