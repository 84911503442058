import { cx } from "@withjuly/frontend-common";
import React from "react";
import { IconType } from "react-icons";

export interface EditorMenuButtonProps {
	icon: IconType;
	title: string;
	action: () => boolean;
	isActive: () => boolean;
	type: "button";
}

export const EditorMenuButton: React.FC<EditorMenuButtonProps> = ({
	title,
	icon,
	isActive,
	action,
}) => {
	return React.createElement(icon, {
		className: cx(
			"cursor-pointer rounded hover:bg-gray-400 p-1 w-6 h-auto",
			isActive() && "bg-gray-250",
		),
		"aria-label": title,
		onClick: action,
		tabIndex: 0,
	});
};
