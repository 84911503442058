import { Editor, EditorContent } from "@tiptap/react";
import React from "react";
import { AttachedFile } from "./AttachedFile";
import { EditorMenu } from "./EditorMenu";

export interface RichTextEditorFilesConfig {
	attached: { name: string; uuid: string }[];
	onDownload: (uuid: string, name: string) => void;
	onRemove: (uuid: string) => void;
}

export interface RichTextEditorProps {
	editor: Editor | null;
	files?: RichTextEditorFilesConfig;
}

export const RichTextEditorComponent: React.FC<
	React.HTMLProps<HTMLInputElement> & RichTextEditorProps
> = ({ editor, files }) => {
	if (!editor) {
		return <div className="min-h-[24rem]" />;
	}

	return (
		<div className="flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-gray-600">
			<EditorContent
				editor={editor}
				className="overflow-y-auto overflow-x-hidden px-4 py-3 text-sm outline-none [&>div]:h-full [&>div]:max-h-[24rem] [&>div]:min-h-[18rem] [&>div]:outline-none [&>div]:ring-0"
			/>
			{(files?.attached.length ?? 0) > 0 && (
				<div className="mx-4 my-2 flex gap-4">
					{files?.attached.map((file) => (
						<AttachedFile
							key={file.uuid}
							name={file.name}
							uuid={file.uuid}
							onDownload={() => files.onDownload(file.uuid, file.name)}
							onRemove={() => files.onRemove(file.uuid)}
						/>
					))}
				</div>
			)}
			<EditorMenu editor={editor} />
		</div>
	);
};

export const RichTextEditor = React.forwardRef<
	HTMLInputElement,
	React.ComponentProps<typeof RichTextEditorComponent>
>((props, ref) => RichTextEditorComponent({ ...props, ref }));
RichTextEditor.displayName = "RichTextEditor";
