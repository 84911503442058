import React, { Dispatch, SetStateAction, useId } from "react";
import { useFormContext } from "react-hook-form";

import { RichTextEditor } from "./RichTextEditor";
import { Editor } from "@tiptap/react";
import axios from "axios";
import { AttachedFile, RichTextProvider } from "~/utils/context/rich-text";
import { FormInputWrapper } from "@withjuly/solis";
import { trpc } from "~/components/Utility/trpc";

export interface RichTextInputProps {
	name: string;
	label?: string;
	editor: Editor | null;
	attachments?: {
		files: AttachedFile[];
		setFiles: Dispatch<SetStateAction<AttachedFile[]>>;
	};
}

export const RichTextInput: React.FC<RichTextInputProps> = ({
	name,
	label,
	editor,
	attachments,
}) => {
	const { register } = useFormContext();
	const downloadAttachment = trpc.template.attachment.getByUuid.useMutation();
	const id = useId();

	const onDownload = async (uuid: string, name: string) => {
		const { url: downloadUrl } = await downloadAttachment.mutateAsync(uuid);
		const file = await axios.get(downloadUrl, { responseType: "blob" });

		const url = window.URL.createObjectURL(new Blob([file.data]));
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", name);
		document.body.appendChild(link);
		link.click();
	};

	const onRemove = (uuid: string) => {
		attachments?.setFiles((prev) => {
			return prev.filter((f) => f.uuid !== uuid);
		});
	};

	return (
		<RichTextProvider
			files={attachments?.files ?? []}
			setFiles={
				attachments?.setFiles ??
				(() => {
					/* Do nothing */
				})
			}
		>
			<FormInputWrapper name={name} label={label} id={id}>
				<RichTextEditor
					editor={editor}
					{...register(name)}
					files={
						attachments
							? {
									attached: attachments?.files,
									onDownload,
									onRemove,
								}
							: undefined
					}
				/>
			</FormInputWrapper>
		</RichTextProvider>
	);
};
