import React, { useId } from "react";
import {
	FormInputWrapper,
	TextInputProps,
	useFormWrapper,
	TextInput as SolisTextInput,
} from "@withjuly/solis";

export interface SolisTextInputProps extends TextInputProps {
	name: string;

	label?: string;
	help?: string;

	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setValueAs?: (value: string) => any;
}

export const TextInput: React.FC<SolisTextInputProps> = ({
	name,
	help,
	label,
	onChange,
	onBlur,
	setValueAs,
	...rest
}) => {
	const id = useId();
	const { register, error, hasError } = useFormWrapper(name);
	const form = register(name, {
		onChange,
		onBlur,
		setValueAs,
	});

	const describedByIds: string[] = [];
	if (help) {
		describedByIds.push(id + "-help");
	}
	if (error?.message) {
		describedByIds.push(id + "-error");
	}

	return (
		<FormInputWrapper id={id} name={name} label={label} help={help}>
			<SolisTextInput
				isInvalid={hasError}
				aria-describedby={describedByIds.join(" ")}
				{...rest}
				{...form}
			/>
		</FormInputWrapper>
	);
};
