import { TemplateVariableData, templateVariables } from "@withjuly/fabric";
import { cx } from "@withjuly/frontend-common";
import { Lightning } from "@withjuly/julycons";
import {
	Text,
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@withjuly/solis";
import React, { useCallback, useRef } from "react";
import { useState } from "react";
import { useOnClickOutside } from "~/utils/hooks/outside-click";

export interface EditorMenuDropdownProps {
	title: string;
	options: { name: string; displayName: string }[];
	action: (option: { name: string; displayName: string }) => void;
	type: "dropdown";
}

export const EditorMenuDropdown: React.FC<EditorMenuDropdownProps> = (
	props,
) => {
	const [isOpen, setIsOpen] = useState(false);
	const ref = useRef(null);
	const handler = useCallback(() => setIsOpen(false), [setIsOpen]);
	useOnClickOutside(ref, handler);

	return (
		<div className="select-none" ref={ref}>
			<DropdownMenu>
				<DropdownMenuTrigger asChild>
					<div
						className={cx(
							"flex cursor-pointer items-center gap-1 rounded px-2 py-1 hover:bg-gray-400",
							isOpen ? "bg-gray-250" : "bg-transparent",
						)}
						onClick={() => setIsOpen(!isOpen)}
					>
						<Lightning className="h-3 w-3 text-blue-300" />
						<Text>Variables</Text>
					</div>
				</DropdownMenuTrigger>
				<DropdownMenuContent
					className="origin-bottom-left border-gray-200 bg-gray-500"
					side="top"
					align="start"
				>
					{props.options.map((option) => {
						const variable = templateVariables[
							option.name
						] as TemplateVariableData;

						return (
							<DropdownMenuItem
								className="mx-1 my-0.5 flex cursor-pointer items-center gap-2 rounded transition-all duration-75 hover:bg-blue-700"
								onClick={() => props.action(option)}
								key={option.name}
							>
								<Lightning className="h-4 w-4 bg-blue-700 text-blue-300" />
								<Text>{variable?.displayName ?? option.name}</Text>
							</DropdownMenuItem>
						);
					})}
				</DropdownMenuContent>
			</DropdownMenu>
		</div>
	);
};
