import React from "react";
import { EditorMenuButton, EditorMenuButtonProps } from "./EditorMenuButton";
import {
	EditorMenuDropdown,
	EditorMenuDropdownProps,
} from "./EditorMenuDropdown";
import {
	EditorMenuFileInput,
	EditorMenuFileInputProps,
} from "./EditorMenuFileInput";
import { EditorMenuLink, EditorMenuLinkInputProps } from "./EditorMenuLink";

export type EditorMenuItemProps =
	| EditorMenuButtonProps
	| EditorMenuDropdownProps
	| EditorMenuLinkInputProps
	| EditorMenuFileInputProps;

export const EditorMenuItem: React.FC<EditorMenuItemProps> = (props) => {
	if (props.type === "button") {
		return <EditorMenuButton {...props} />;
	} else if (props.type === "link") {
		return <EditorMenuLink {...props} />;
	} else if (props.type === "file") {
		return <EditorMenuFileInput {...props} />;
	} else {
		return <EditorMenuDropdown {...props} />;
	}
};
