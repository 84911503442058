import { cx } from "@withjuly/frontend-common";
import { Button } from "@withjuly/solis";
import React, { useCallback, useRef } from "react";
import { useState } from "react";
import { IconType } from "react-icons";
import { useOnClickOutside } from "~/utils/hooks/outside-click";
import { EditorMenuButton } from "./EditorMenuButton";

export interface EditorMenuLinkInputProps {
	icon: IconType;
	title: string;
	action: (input: string) => void;
	type: "link";
}

export const EditorMenuLink: React.FC<EditorMenuLinkInputProps> = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const [location, setLocation] = useState("");
	const ref = useRef(null);
	const handler = useCallback(() => setIsOpen(false), [setIsOpen]);
	useOnClickOutside(ref, handler);

	const onSave = () => {
		const link =
			location.indexOf("://") === -1 ? "http://" + location : location;
		props.action(link);
		setIsOpen(false);
	};

	return (
		<>
			<EditorMenuButton
				{...props}
				type="button"
				isActive={() => isOpen}
				action={() => {
					setIsOpen(!isOpen);
					setLocation("");
					return isOpen;
				}}
			/>
			{isOpen && (
				<div
					className={cx(
						"shadow-20 absolute bottom-0 my-1 mb-16 flex gap-1 rounded-lg border border-gray-200 bg-gray-400 p-2",
					)}
					ref={ref}
				>
					<input
						className="text-white-100 rounded bg-transparent placeholder:text-gray-100"
						placeholder="Insert link"
						value={location}
						onChange={(e) => setLocation(e.target.value)}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								e.preventDefault();
								onSave();
							}
						}}
					/>
					<Button
						size="small"
						onClick={(e) => {
							e.preventDefault();
							onSave();
						}}
					>
						Save
					</Button>
				</div>
			)}
		</>
	);
};
